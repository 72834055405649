// responsive		

@mixin breakpoint($point) {
	@if $point == cero {
		@media (min-width: 0) { @content ; }
	}
	@if $point == mobile {
		@media (min-width: 540px) { @content ; }
	}
	@else if $point == tablet {
		@media (min-width: 720px) { @content ; }
	}
	@else if $point == desktop {
		@media (min-width: 992px) { @content ; }
	}
	@else if $point == extra {
		@media (min-width: 1140px) { @content ; }
	}
	@else if $point == extra-plus {
		@media (min-width: 1200px) { @content ; }
	}
	@else if $point == mega {
		@media (min-width: 1400px) { @content ; }
	}
}
