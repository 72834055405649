@import "./colors";
@import "./fonts";
@import "./mixing_artefacts";
@import "./responsive";
@import "./form";
@import "./tabs";
@import "./login";

// overrides

// body
body {
	font-size: 14px;

	.ant {
		&-card-head-title {
			font-family: $font_primary;
		}
		&-table-wrapper {
			padding: 1rem;
		}

		&-pagination {
			&-item {
				&-link {
					display: flex !important;
					align-items: center !important;
				}
				&-active {
					a,
					a:link {
						background-color: $color_secondary;
						color: $color_white !important;
					}
				}
			}
		}
	}
}

// text
.text {
	font-family: $font_primary;
	font-size: 1rem;
	@extend %transform_05;
	&.text {
		&-color {
			&-primary {
				color: $color_primary;
			}
			&-secondary {
				color: $color_secondary;
			}
			&-bg_1 {
				color: $color_bg_1;
			}
			&-bg_2 {
				color: $color_bg_2;
			}
			&-bg_3 {
				color: $color_bg_3;
			}
			&-black {
				color: $color_black;
			}
			&-white {
				color: $color_white;
			}
			&-transparent {
				color: $color_transparent;
			}
		}
		&-size {
			&-0 {
				font-size: 0;
				&-3 {
					font-size: 0.3rem;
				}
				&-4 {
					font-size: 0.4rem;
				}
				&-5 {
					font-size: 0.5rem;
				}
				&-6 {
					font-size: 0.6rem;
				}
				&-7 {
					font-size: 0.7rem;
				}
				&-8 {
					font-size: 0.8rem;
				}
				&-9 {
					font-size: 0.9rem;
				}
			}
			&-1 {
				font-size: 1rem;
				&-2 {
					font-size: 1.2rem;
				}
				&-3 {
					font-size: 1.3rem;
				}
				&-4 {
					font-size: 1.4rem;
				}
				&-5 {
					font-size: 1.5rem;
				}
				&-6 {
					font-size: 1.6rem;
				}
				&-7 {
					font-size: 1.7rem;
				}
				&-8 {
					font-size: 1.8rem;
				}
			}
			&-2 {
				font-size: 2rem;
				&-2 {
					font-size: 2.2rem;
				}
				&-3 {
					font-size: 2.3rem;
				}
				&-4 {
					font-size: 2.4rem;
				}
				&-5 {
					font-size: 2.5rem;
				}
				&-6 {
					font-size: 2.6rem;
				}
				&-7 {
					font-size: 2.7rem;
				}
				&-8 {
					font-size: 2.8rem;
				}
			}
			&-3 {
				font-size: 3rem;
				&-2 {
					font-size: 3.2rem;
				}
				&-3 {
					font-size: 3.3rem;
				}
				&-4 {
					font-size: 3.4rem;
				}
				&-5 {
					font-size: 3.5rem;
				}
				&-6 {
					font-size: 3.6rem;
				}
				&-7 {
					font-size: 3.7rem;
				}
				&-8 {
					font-size: 3.8rem;
				}
			}
			&-4 {
				font-size: 4rem;
				&-2 {
					font-size: 4.2rem;
				}
				&-3 {
					font-size: 4.3rem;
				}
				&-4 {
					font-size: 4.4rem;
				}
				&-5 {
					font-size: 4.5rem;
				}
				&-6 {
					font-size: 4.6rem;
				}
				&-7 {
					font-size: 4.7rem;
				}
				&-8 {
					font-size: 4.8rem;
				}
			}
			&-5 {
				font-size: 5rem;
			}
			&-6 {
				font-size: 6rem;
			}
			&-7 {
				font-size: 7rem;
			}
			&-8 {
				font-size: 8rem;
			}
			&-9 {
				font-size: 9rem;
			}
		}

		&-w {
			&-light {
				font-weight: 300;
			}
			&-reg {
				font-weight: 400;
			}
			&-med {
				font-weight: 500;
			}
			&-semi {
				font-weight: 600;
			}
			&-bold {
				font-weight: 700;
			}
		}

		&-spacing {
			&-0 {
				&-5 {
					letter-spacing: 0.5rem;
				}
			}
		}
	}
	&-column {
		&-2 {
			@include breakpoint(desktop) {
				columns: 2;
			}
		}
	}
}

// icon
.icon {
	@extend %transform_05;
	&.icon {
		&-color {
			&-primary {
				color: $color_primary;
			}
			&-secondary {
				color: $color_secondary;
			}
			&-bg_1 {
				color: $color_bg_1;
			}
			&-bg_2 {
				color: $color_bg_2;
			}
			&-bg_3 {
				color: $color_bg_3;
			}
			&-black {
				color: $color_black;
			}
			&-white {
				color: $color_white;
			}
			&-transparent {
				color: $color_transparent;
			}
		}
		&-size {
			&-0 {
				font-size: 0;
				&-3 {
					font-size: 0.3rem;
				}
				&-4 {
					font-size: 0.4rem;
				}
				&-5 {
					font-size: 0.5rem;
				}
				&-6 {
					font-size: 0.6rem;
				}
				&-7 {
					font-size: 0.7rem;
				}
				&-8 {
					font-size: 0.8rem;
				}
				&-9 {
					font-size: 0.9rem;
				}
			}
			&-1 {
				font-size: 1rem;
				&-2 {
					font-size: 1.2rem;
				}
				&-3 {
					font-size: 1.3rem;
				}
				&-4 {
					font-size: 1.4rem;
				}
				&-5 {
					font-size: 1.5rem;
				}
				&-6 {
					font-size: 1.6rem;
				}
				&-7 {
					font-size: 1.7rem;
				}
				&-8 {
					font-size: 1.8rem;
				}
			}
			&-2 {
				font-size: 2rem;
				&-2 {
					font-size: 2.2rem;
				}
				&-3 {
					font-size: 2.3rem;
				}
				&-4 {
					font-size: 2.4rem;
				}
				&-5 {
					font-size: 2.5rem;
				}
				&-6 {
					font-size: 2.6rem;
				}
				&-7 {
					font-size: 2.7rem;
				}
				&-8 {
					font-size: 2.8rem;
				}
			}
			&-3 {
				font-size: 3rem;
				&-2 {
					font-size: 3.2rem;
				}
				&-3 {
					font-size: 3.3rem;
				}
				&-4 {
					font-size: 3.4rem;
				}
				&-5 {
					font-size: 3.5rem;
				}
				&-6 {
					font-size: 3.6rem;
				}
				&-7 {
					font-size: 3.7rem;
				}
				&-8 {
					font-size: 3.8rem;
				}
			}
			&-4 {
				font-size: 4rem;
				&-2 {
					font-size: 4.2rem;
				}
				&-3 {
					font-size: 4.3rem;
				}
				&-4 {
					font-size: 4.4rem;
				}
				&-5 {
					font-size: 4.5rem;
				}
				&-6 {
					font-size: 4.6rem;
				}
				&-7 {
					font-size: 4.7rem;
				}
				&-8 {
					font-size: 4.8rem;
				}
			}
			&-5 {
				font-size: 5rem;
			}
			&-6 {
				font-size: 6rem;
			}
		}
	}
}

// bg
.bg {
	&.bg {
		&-color {
			&-primary {
				background-color: $color_primary;
			}
			&-secondary {
				background-color: $color_secondary;
			}
			&-bg_1 {
				background-color: $color_bg_1;
			}
			&-bg_2 {
				background-color: $color_bg_2;
			}
			&-bg_3 {
				background-color: $color_bg_3;
			}
			&-black {
				background-color: $color_black;
			}
			&-white {
				background-color: $color_white;
			}
			&-transparent {
				background-color: $color_transparent;
			}
		}

		&-image {
			background-position: center center;
			background-size: cover;
			background-repeat: no-repeat;
		}
	}
}

// btn
.btn {
	@extend %transform_05;
	cursor: pointer;
	&:focus {
		box-shadow: none;
		outline: initial;
	}
	&.btn {
		&-h {
			&-auto {
				height: auto;
			}
		}
	}
}

// img
.img {
	@extend %transform_05;
	&.img {
		&-auto {
			object-fit: cover;
		}
		&-containt {
			object-fit: contain;
		}
		&-w {
			width: 100%;
			&-2 {
				width: 2rem;
			}
			&-3 {
				width: 3rem;
			}
			&-4 {
				width: 4rem;
			}
			&-5 {
				width: 5rem;
			}
		}
		&-h {
			height: 100%;
			&-250 {
				height: 250px;
			}
			&-350 {
				height: 350px;
			}
			&-400 {
				height: 400px;
			}
			&-450 {
				height: 450px;
			}
			&-500 {
				height: 500px;
			}
			&-600 {
				height: 600px;
			}
			&-700 {
				height: 700px;
			}
		}
		&-maxWidth {
			max-width: 100%;
		}
	}
}

// position
.position {
	&.position {
		&-z {
			&-1 {
				z-index: 1000;
			}
			&-2 {
				z-index: 2000;
			}
			&-3 {
				z-index: 3000;
			}
			&-4 {
				z-index: 4000;
			}
			&-5 {
				z-index: 5000;
			}
		}
	}
}

// list
.list {
	&-none {
		list-style: none;
		padding: 0;
	}
}

.mx-auto {
	margin: auto;
}
.mb {
	&-5 {
		margin-bottom: 2rem;
	}
}
.p-3 {
	padding: 1rem;
}

// finalizar pago
.finalizar-pago {
	.ant-spin-nested-loading {
		margin: 3rem auto 0;
	}
	.ant-card-body {
		form {
			margin-bottom: 2rem;
			.ant-btn {
				height: auto;
			}
		}
	}
}

.text {
	&-right {
		text-align: right;
	}
	&-md {
		&-center {
			@include breakpoint(tablet) {
				text-align: center;
			}
		}
	}
}

#root {
	display: flex;
	justify-content: center;
}

.d-block {
	display: block;
}

.ant {
	&-table-wrapper {
		overflow: auto;
	}
	&-layout {
		width: 100vw;
	}
	&-row {
		margin-left: auto;
		margin-right: auto;
	}
	&-pagination {
		.ant-pagination-item {
			border-color: #ddd !important;
			margin: auto 0.25rem !important;
			overflow: hidden !important;
			a {
				background-color: #fff !important;
			}
			&-active {
				a {
					background-color: #2bc6ce !important;
				}
			}
		}
	}
}

.justify-flex-end {
	display: flex;
	justify-content: flex-end;
}

.modal-section-name-text {
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
}

.text-option-value {
	font-size: 14px;
	color: rgba(0, 0, 0, 0.65);
	font-family: Roboto-Regular;
}
