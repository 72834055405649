// colors

$color_primary: #114d69;
$color_secondary: #63c3cc;
$color_green:       #2fc25b;

// gray
$color_bg_1:     #c5d0d8;
$color_bg_2:     #f0f2f5;
$color_bg_3:     #fafafa;
$color_border:      #d9d9d9;

// basic
$color_black:           #000000;
$color_white:           #FFFFFF;
$color_transparent:     transparent;