@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Roboto-Black';
  src: local('Roboto-Black'), url(./assets/fonts/Roboto-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto-Bold'), url(./assets/fonts/Roboto-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Italic';
  src: local('Roboto-Italic'), url(./assets/fonts/Roboto-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Italic';
  src: local('Roboto-Italic'), url(./assets/fonts/Roboto-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Light';
  src: local('Roboto-Light'), url(./assets/fonts/Roboto-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'), url(./assets/fonts/Roboto-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'), url(./assets/fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Thin';
  src: local('Roboto-Thin'), url(./assets/fonts/Roboto-Thin.ttf) format('truetype');
}

.container-pay-collection-modal,
.container-modal {
  padding: '0 20px',
}

.text-title-description,
.text-option-value {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65)
}

.input-pay-collection-modal,
.input-collection-request-modal {
  border-radius: 4;
  font-size: 14px;
  width: 100%;
  padding-top: 6;
}

.input-group-collection-request-modal {
  font-size: 14px;
  width: 70%;
}

.select-group {
  font-size: 14px;
  width: 30%;
}

.select-input {
  font-size: 14px;
  border-radius: 4;
  border-color: 'white';
  border-width: 1px;
}

.icon-collection-request {
  color: "#2BC6CE"
}

.icon-disabled,
.icon-disabled:hover {
  color: #FB3254;
}

.button-link {
  font-family: 'Roboto-Regular';
  font-size: 14px;
}

div.attachment-container {
  width: 100%;
  background-color: '#FAFAFA';
  padding-left: 46px;
  padding-top: 20px;
  padding-right: 46px;
  padding-bottom: 20px;
}

.truncate-text-container {
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden
}

.input-collection-request-table {
  border-radius: 4;
  border-color: white;
  font-size: 14px;
  width: 188px;
  margin-bottom: 8px;
  display: block;
}

.button-primary {
  font-family: Roboto-Bold;
  font-weight: 600;
  font-size: 16px;
  width: 90px;
}

.button-default {
  font-family: Roboto-Regular;
  font-size: 14px;
  width: 130px;
}

.row-filter {
  width: 100%;
  padding: 16px;
  background-color: #F0F2F5,
}

.text-title {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  vertical-align: middle,
}

.button-primary-filters {
  font-family: Roboto-Bold;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  margin-left: 8px;
}


.button-default-filters {
  font-family: Roboto-Regular;
  font-size: 14px;
  width: 100%;
}

.input-filter {
  border-width: 1px;
  padding-top: 6px;
  width: 100%;
  border-radius: 4;
  font-size: 14px;
}

.icon-filter {
  color: rgba(0, 0, 0, 0.25);
}

.text-title-popconfirm {
  font-size: 16px;
  font-family: Roboto-Medium
}

.text-ok-button-popconfirm {
  font-size: 16px;
  font-weight: 600;
  font-family: Roboto-Bold;
  color: white;
}

.text-cancel-btn-popconfirm {
  font-size: 16px;
  font-family: Roboto-Regular
}