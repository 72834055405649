// .AdobeStock_91340865-neg {
//     width: 100%;
//     //height: 317px;
//     object-fit: contain;
// }
.Group-15 {
    width: 213px;
    height: 56px;
    object-fit: contain;
}
.Contactar-con-ADGTra {
    width: 157px;
    height: 20px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #ffffff;
}
.Contactar-con-ADGTra .text-style-1 {
    color: #2bc6ce;
}
