// tabs / accordeon

.c_tabs {
    //background-color: $color_white;
    border: none;
    .ant {
        &-collapse {
            &-header {
                border: none;
                width: 100%;
            }
            &-content {
                background-color: $color_bg_1;
                border: none;
                &-box {
                    @extend %transform_05;
                }
            }
            &-item {
                &-active {
                    .ant-collapse-content-box { padding-top: 0 !important; }
                }
            }
        }
    }
}