.text-title-popconfirm {
    font-size: 16px;
    font-family: Roboto-Medium;
}

.icon {
    color: #2BC6CE;
}

.select-modal {
    font-family: Roboto-Regular;
    font-size: 16px;
    border-radius: 4;
    border-width: 1px;
    border-color: white;
}

.button-primary-modal {
    font-family: Roboto-Bold;
    font-weight: 600;
    font-size: 16px;
    width: 100%;
}

.input-modal {
    width: 100%;
    border-radius: 4;
    border-color: #D9D9D9;
    font-family: Roboto-Regular;
    font-size: 16px;
}

.text-option-value {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    font-family: Roboto-Regular;
}